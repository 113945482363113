body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E5E5E5;
  color: black;
}

span .icon {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.selectedButton{
  background-color: #605E5C;
  color: white;
  border-radius: 80px;
}

.trackerslist .ms-DetailsHeader{
  background: #E5E5E5;
}

.trackerslist .ms-DetailsHeader-cellName{
  text-transform: capitalize;
}

.trackerslist .ms-DetailsRow{
  border-radius: 7px;
    margin-bottom: 4px;
    box-shadow:0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
}

.trackerslist .ms-DetailsRow-cell{
  display:flex;
  align-items: center;
}

.Ringerbutton {
  font-size: 20px;
}

.ms-Panel-navigation{
  justify-content: space-between;
}

/* #root{
  height: 100vh;
} */