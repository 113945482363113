.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #333;
  overflow-x: hidden;
  transition: 0.3s;
  padding-top: 60px;
  color: white;
}

.sidenav.open {
  width: 250px; /* Adjust as needed */
}

.groupContent-109 {
    margin-bottom: 0;
}

.highlight-icon {
  /* background-color: #333; */
  box-shadow: -2px 2px 5px 0px rgba(0, 0, 0, 0.5);
}

.custom-message-bar {
  opacity: 0;
  transition: opacity 500ms, height 600ms, min-height 600ms, margin-top 620ms, margin-left 650ms ease-in-out;
  min-height: 0;
  height: 0;
  margin: 0px;
}

.custom-message-bar.visible {
  min-height: 32px;
  height: 32px;
  margin-top: 15px;
  opacity: 1;
}

.mermaid-content {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.mermaid-content.visible {
  opacity: 1;
}
